// Token
export const loadTokenState = () => {
  try {
    const serializedAccessToken = localStorage.getItem("access_token");
    return JSON.parse(serializedAccessToken)
  } catch (error) {
    console.error("Error while retrieving tokens from local storage:", error);
    return undefined;
  }
};

export const saveTokenState = (accessToken) => {
  try {
    localStorage.setItem("access_token", JSON.stringify(accessToken));
  } catch (error) { }
};

export const removeTokenState = () => {
  try {
    localStorage.removeItem("access_token");
  } catch (error) {
    console.log("error:", error);
  }
};
