import React, { useState, useEffect } from "react";
import { Row, Col, Button, Form, Input, Spin, notification, Upload } from "antd";
import { useUpdateBergeriesMutation } from "../services/bergeries-api";
import { UploadOutlined } from "@ant-design/icons";
import { API_ROOT } from "../../../utils/global-vars";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import 'animate.css';
const { TextArea } = Input;


export default function BergeriesEditPage() {
    const { state } = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const [updateBergeries] = useUpdateBergeriesMutation();
    const { user } = useSelector((state) => state.auth);
    const [form] = Form.useForm();
    const [filename, setFilename] = useState();


    const onFinish = (values) => {
        const data = { ...values, id: state.id, image: filename ?? state.image, userId: user.id }
        setIsLoading(true);
        updateBergeries(data)
            .unwrap()
            .then(() => {
                setIsLoading(false);
                notification.success({ message: 'mise à jour effectuée' })
            })
            .catch((error) => {
                setIsLoading(false);
                notification.error({ message: error.message })
            });
    };

    const props = {
        name: "file",
        multiple: false,
        action: `${API_ROOT}/uploads/single`,
        onChange(info) {
            setFilename(info.file.response?.filename);
        },
        onDrop(e) {
            console.log("Dropped files", e.dataTransfer.files);
        },
    };

    useEffect(() => {
        form.setFieldsValue(state);
    }, [state]);


    return (
        <div className="animate__animated animate__fadeIn account-profile">
            <div className="title">
                <h3>Editer une Bergerie </h3>
            </div>
            <div className="content">
                <Spin tip="Chargement..." spinning={isLoading}>
                    <div className="form-wrapper">
                        <Form name="basic" layout="vertical" autoComplete="off" onFinish={onFinish} form={form}>
                            <Row gutter={24}>
                                <Col lg={12} xs={24}>
                                    <Form.Item label="Nom" name="libelle" rules={[{ required: true, message: "Champs requis!" }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col lg={12} xs={24}>
                                    <Form.Item label="Adresse" name="adresse" rules={[{ required: true, message: "Champs requis!" }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row>
                                <Col lg={24} xs={24}>
                                    <Form.Item label="Description" name="description">
                                        <TextArea rows={4} className="form-control" />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <div style={{ margin: '20px 0px' }}>
                                <Form.Item
                                    label="Image"
                                    name="image"
                                    rules={[{ required: true, message: "Champs requis!" }]}>
                                    <Upload {...props}>
                                        <Button icon={<UploadOutlined />}>
                                            Click to Upload
                                        </Button>
                                    </Upload>
                                </Form.Item>
                            </div>
                            <Form.Item>
                                <Button type="primary" className="btn btn-primary" htmlType="submit">
                                    Enregistrer
                                </Button>
                            </Form.Item>
                        </Form>
                        <br />
                    </div>
                </Spin>
            </div>
        </div>
    );
}
