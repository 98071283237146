import React from 'react';
import './styles/styles.scss';
import { Row, Col } from 'antd';
import { NavLink } from 'react-router-dom';


export default function Footer() {
    return (
        <div className="footer">
            <div className='container'>
                <Row gutter={24}>
                    <Col lg={6}>
                        <div className='blc'>
                            <div className='title'>Univers des Ladoums</div>
                            <div className='menu'>
                                <ul>
                                    <li><NavLink to={""}>Accueil</NavLink></li>
                                    <li><NavLink to={""}>A propos de nous</NavLink></li>
                                    <li><NavLink to={""}>Nos Moutons</NavLink></li>
                                    <li><NavLink to={""}>Bloc</NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </Col>

                    <Col lg={6}>
                        <div className='blc'>
                            <div className='title'>Informations</div>
                            <div className='menu'>
                                <ul>
                                    <li><NavLink to={""}>Contact</NavLink></li>
                                    <li><NavLink to={""}>FAQ</NavLink></li>
                                    <li><NavLink to={""}>Mentions légales</NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </Col>

                    <Col lg={6}>
                        <div className='blc'>
                            <div className='title'>Adress & Contact</div>
                            <div className='menu'>
                                <ul>
                                    <li><NavLink to={""}>Dakar, Sénégal</NavLink></li>
                                    <li><NavLink to={""}>+221 77 XXX XX XX / 76 XXX XX XX</NavLink></li>
                                    <li><NavLink to={""}>Contact@Universdesladoums.udl</NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </Col>

                    <Col lg={6}>
                        <div className='blc'>

                        </div>
                    </Col>
                </Row>
                <div className='copyright'>
                    © 2024 - Univers des Ladoums - Tous droits réservés
                </div>
            </div>
        </div>
    );
}
