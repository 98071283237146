import { createSlice } from "@reduxjs/toolkit";
import { loadTokenState } from "../../../utils/local-storage";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    token: loadTokenState()?.accessToken,
    user: loadTokenState()?.user,
    type: "",
  },
  reducers: {
    logout: (state) => {
      localStorage.removeTokenState();
      state.user = null;
      state.token = null;
    },
    setToken: (state, { payload }) => {
      state.token = payload;
      return state;
    },

    setUser: (state, { payload }) => {
      state.user = payload;
      return state;
    },

    checkPage: (state, { payload }) => {
      state.type = payload;
      return state;
    },
  },
});

export const { setToken, setUser, logout, checkPage } = authSlice.actions;
export default authSlice.reducer;
