import React from "react";
import { Col, Row, Skeleton } from 'antd';
import 'animate.css';
import './styles/style.scss';
import Banner from "../../components/Banner/Banner";
import { Link } from "react-router-dom";
import { useFetchPostsQuery } from "./services/post-api";

export default function PostsPage() {
    const posts = useFetchPostsQuery();

    return (
        <div className="post-page animate__animated animate__fadeIn">
            <Banner title="Blog" subtitle="Lorem Ipsum is simply dummy text of the printing and typesetting industry." page="blog" />
            <div className="section-1">
                <div className="container">
                    <Row gutter={24}>
                        {
                            posts.isFetching ?
                                <Skeleton paragraph={{ rows: 10 }} /> :
                                posts.data.map((item, index) => (
                                    <Col lg={6}>
                                        <div className="blc" key={index}>
                                            <Link to={`/posts/${item.slug}`}>
                                                <div className="blc-img" style={{ backgroundImage: `url(${item.image})` }}>
                                                    <div className="blc-img-backdrop" />
                                                    <div className="blc-cat">Actualités</div>
                                                </div>
                                                <div className="blc-title"><div dangerouslySetInnerHTML={{ __html: item.title.rendered }} /></div>
                                            </Link>
                                        </div>
                                    </Col>
                                ))
                        }
                    </Row>
                </div>
            </div>
        </div >
    );
}
