import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Form, Input, Alert } from "antd";
import { useNavigate } from "react-router-dom";
import { useAuthloginMutation, useAuthProfileMutation } from "../services/auth-api";
import { saveTokenState } from "../../../utils/local-storage";
import { useDispatch } from "react-redux";
import { setUser } from '../services/auth-slice';
import 'animate.css';
import '../styles/style.scss';

export default function LoginPage() {
    const dispatch = useDispatch();
    const [authlogin] = useAuthloginMutation();
    const [authProfile] = useAuthProfileMutation();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState();
    const navigate = useNavigate();

    const onFinish = (values) => {
        setIsLoading(true);
        authlogin(values)
            .unwrap()
            .then((res) => {
                saveTokenState(res)
                handleFetchUser(res.accessToken)
            })
            .catch((error) => {
                setIsLoading(false);
                setError(error?.data?.message);
            });
    };

    const handleFetchUser = async (accessToken) => {
        authProfile({ "access_token": accessToken })
            .unwrap()
            .then((res) => {
                dispatch(setUser(res))
                navigate("/account");
            })
            .catch(() => {
                setIsLoading(false);
            });
    };

    return (
        <div className="auth-page animate__animated animate__fadeIn">
            <div className='container'>
                <div className='section-auth'>
                    <h2>Connexion</h2>
                    <p>Connectez-vous à votre compte</p>
                    <Form
                        name="basic"
                        initialValues={{ phone: "", password: "" }}
                        onFinish={onFinish}
                        layout="vertical"
                        autoComplete="off"
                    >
                        <Form.Item
                            label="Numéro de téléphone"
                            name="phone"
                            rules={[{ required: true, message: "Champs requis!" }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Mot de passe"
                            name="password"
                            rules={[{ required: true, message: "Champs requis!" }]}
                        >
                            <Input.Password />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" className="btn btn-lg btn-primary" htmlType="submit" loading={isLoading}>
                                Connexion
                            </Button>
                        </Form.Item>
                    </Form>
                    {error && <Alert message={error} type="error" />}
                    <p>
                        Vous n'avez pas de compte ?{" "}
                        <Link to={"/register"}>
                            Inscrivez-vous{" "}
                        </Link>
                    </p>
                    <p>
                        <Link to={"/reset-password"}>Mot de passe oublié?</Link>
                    </p>
                </div>
            </div>
        </div>
    );
}
