import React, { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setToken, setUser } from "../../../auth/services/auth-slice";
import { useAuthProfileMutation } from "../../../auth/services/auth-api";
import "./styles/style.scss"
import { loadTokenState, removeTokenState } from "../../../../utils/local-storage";

function AccountMenu() {
    const [authProfile] = useAuthProfileMutation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleLogout = async () => {
        removeTokenState()
        dispatch(setToken())
        dispatch(setUser())
        navigate('/')
    }

    const handleFetchUser = async () => {
        authProfile({ "access_token": loadTokenState()?.accessToken })
            .unwrap()
            .then((res) => {
                dispatch(setUser(res))
                navigate("/account");
            })
            .catch((error) => {
                if ((error.data.message === "jwt must be provided") || (error.data.message === "jwt expired")) {
                    handleLogout();
                }
            });
    };

    useEffect(() => {
        handleFetchUser();
    }, []);


    return (
        <div className="animate__animated animate__fadeIn account-menu">
            <div className="menu">
                <div className="menu-item"><NavLink to="/account">Profil</NavLink></div>
                <div className="menu-item"><NavLink to="/account/bergeries-create">Ajouter une bergerie</NavLink></div>
                <div className="menu-item"><NavLink to="/account/parent-create">Ajouter parent</NavLink></div>
                <div className="menu-item"><NavLink to="/account/ladoums-create">Ajouter un Ladoum</NavLink></div>
                <div className="menu-item"><NavLink to="/account/bergeries-list">Bergeries</NavLink></div>
                <div className="menu-item"><NavLink to="/account/parent-list">Parents</NavLink></div>
                <div className="menu-item"><NavLink to="/account/ladoums-list">Mes Ladoums</NavLink></div>
                <div className="menu-item"><span className="logout-link" onClick={() => handleLogout()}>Déconnexion</span></div>
            </div>
        </div>
    );
}

export default AccountMenu;
