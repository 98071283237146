import React from "react";
import { NavLink } from 'react-router-dom';
import { CircleUserRound } from 'lucide-react';
import { useSelector } from 'react-redux';
import './styles/styles.scss';
import ResponsiveMenu from "./responsive-menu";


export default function Header() {
    const { user } = useSelector((state) => state.auth);

    return (
        <>
            <ResponsiveMenu />
            <div className="header desktop">
                <div className="header-info">
                    <div className="container-head">
                        <div className="phone">+221 77 443 60 27</div>
                        <div className=""></div>
                    </div>
                </div>
                <div className='container-head'>
                    <div className='nav'>
                        <div className="logo">
                            <NavLink to={""}>
                                <img src='/img/logo.png' alt="" />
                            </NavLink>
                        </div>
                        <div className="menu">
                            <ul>
                                <li><NavLink to={"/"}>Accueil</NavLink></li>
                                <li><NavLink to={"/bergeries"}>Nos bergeries</NavLink></li>
                                <li><NavLink to={"/posts"}>Blog</NavLink></li>
                                <li><NavLink to={"/contact"}>Contact</NavLink></li>
                                {user ?
                                    <li><NavLink to={"/account"}><CircleUserRound /></NavLink></li> :
                                    <li><NavLink to={"/login"}>Se connecter</NavLink></li>
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
