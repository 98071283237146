import { configureStore } from '@reduxjs/toolkit';
import { authApi } from '../pages/auth/services/auth-api';
import { accountApi } from '../pages/account/services/account-api';
import { parentApi } from '../pages/account/services/parent-api';
import authSlice from "../pages/auth/services/auth-slice";
import { bergeriesApi } from '../pages/account/services/bergeries-api';
import { ladoumApi } from '../pages/account/services/ladoums-api';
import { postApi } from '../pages/blog/services/post-api';

export default configureStore({
    reducer: {
        [authApi.reducerPath]: authApi.reducer,
        [accountApi.reducerPath]: accountApi.reducer,
        [parentApi.reducerPath]: parentApi.reducer,
        [bergeriesApi.reducerPath]: bergeriesApi.reducer,
        [ladoumApi.reducerPath]: ladoumApi.reducer,
        [postApi.reducerPath]: postApi.reducer,
        auth: authSlice
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware().concat
            (
                authApi.middleware,
                accountApi.middleware,
                parentApi.middleware,
                bergeriesApi.middleware,
                ladoumApi.middleware,
                postApi.middleware
            )
});