import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { MenuOutlined, CloseOutlined } from "@ant-design/icons";

const ResponsiveMenu = () => {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen((prev) => !prev);
    };

    return (
        <div className="responsive-menu">
            <div className="header">
                <div className="logo">
                    <NavLink to="/">
                        <img src="/img/logo.png" alt="Logo" />
                    </NavLink>
                </div>
                <div className="menu-toggle" onClick={toggleMenu}>
                    {menuOpen ? <CloseOutlined /> : <MenuOutlined />}
                </div>
            </div>

            <div className={`content-menu ${menuOpen ? "open" : ""}`}>
                <ul>
                    <li>
                        <NavLink to="/" onClick={toggleMenu}>
                            Accueil
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/bergeries" onClick={toggleMenu}>
                            Nos bergeries
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/posts" onClick={toggleMenu}>
                            Blog
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/contact" onClick={toggleMenu}>
                            Contact
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/login" onClick={toggleMenu}>
                            Se connecter
                        </NavLink>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default ResponsiveMenu;