import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_ROOT } from "../../../utils/global-vars";

export const postApi = createApi({
    reducerPath: "postApi",
    baseQuery: fetchBaseQuery({ baseUrl: API_ROOT }),
    tagTypes: ["Post"],
    endpoints: (builder) => ({
        fetchPosts: builder.query({
            query: () => `/posts`,
            providesTags: ["Post"],
        }),

        fetchPost: builder.query({
            query: (slug) => `/posts/${slug}`,
            providesTags: ["Post"],
        }),
    }),
});

export const { useFetchPostQuery, useFetchPostsQuery } = postApi;
