import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_ROOT } from "../../../utils/global-vars";
import { loadTokenState } from "../../../utils/local-storage";

export const parentApi = createApi({
    reducerPath: "parentApi",
    baseQuery: fetchBaseQuery({
        baseUrl: API_ROOT,
        prepareHeaders: (headers) => {
            const token = loadTokenState()?.token;
            if (token) {
                headers.set("Authorization", `Bearer ${token}`);
            }
            return headers;
        },
    }),
    tagTypes: ["Parent"],
    endpoints: (builder) => ({
        createParent: builder.mutation({
            query: (payload) => ({
                url: `/parents`,
                method: "POST",
                body: payload,
            }),
            invalidatesTags: ["Parent"],
        }),

        updateParent: builder.mutation({
            query: ({ id, ...payload }) => ({
                url: `/parents/${id}`,
                method: "PUT",
                body: payload,
            }),
            invalidatesTags: ["Parent"],
        }),

        deleteParent: builder.mutation({
            query: (id) => ({
                url: `/parents/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Parent"],
        }),

        fetchAllParents: builder.query({
            query: () => `/parents`,
            providesTags: ["Parent"],
        }),

        fetchParentById: builder.query({
            query: (id) => `/parents/${id}`,
            providesTags: ["Parent"],
        }),

        fetchParentByUseId: builder.query({
            query: (id) => `/parents/users/${id}`,
            providesTags: ["Parent"],
        }),
    }),
});

export const {
    useCreateParentMutation,
    useUpdateParentMutation,
    useDeleteParentMutation,
    useFetchAllParentsQuery,
    useFetchParentByIdQuery,
    useFetchParentByUseIdQuery
} = parentApi;
