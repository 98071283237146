import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_ROOT } from "../../../utils/global-vars";
import { loadTokenState } from "../../../utils/local-storage";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({ baseUrl: API_ROOT }),
  tagTypes: ["Auth"],
  endpoints: (builder) => ({
    authlogin: builder.mutation({
      query: (payload) => ({
        url: "/auth/login/",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Auth"],
    }),

    authRegister: builder.mutation({
      query: (payload) => ({
        url: "/auth/register",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Auth"],
    }),

    authProfile: builder.mutation({
      query: (payload) => ({
        url: "/auth/profile",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Auth"],
    }),
  }),
});

export const {
  useAuthloginMutation,
  useAuthRegisterMutation,
  useAuthProfileMutation
} = authApi;
