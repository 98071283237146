import React, { useState } from "react";
import { Row, Col, Button, Form, Input, Spin, Select, Table } from "antd";
import { useSelector } from "react-redux";
import 'animate.css';
import { useFetchParentByUseIdQuery } from "../services/parent-api";

export default function ParentListPage() {
    const { user } = useSelector((state) => state.auth);
    const { data: products, isLoading } = useFetchParentByUseIdQuery(user.id);

    const columns = [
        {
            title: "Nom du mouton",
            key: "libelle",
            render: (record) => (
                <div className="btn-view">
                    <span>{record.libelle}</span>
                </div>
            ),
        },
        {
            title: "Description",
            dataIndex: "description",
            key: "description",
            render: (text) => <span>{text}</span>,
        },

        {
            title: "Poids",
            dataIndex: "poids",
            key: "poids",
            render: (text) => <span>{text} kg</span>,
        },
        {
            title: "Taille",
            dataIndex: "taille",
            key: "taille",
            render: (text) => <span>{text}</span>,
        },

        {
            title: "Provenance",
            dataIndex: "provenance",
            key: "provenance",
            render: (text) => <span>{text}</span>,
        },


    ];

    return (
        <div className="animate__animated animate__fadeIn account-profile">
            <div className="title">
                <h3>Liste des parents </h3>
            </div>

            <div className="content">
                <Spin tip="Chargement..." spinning={isLoading}>
                    <Table columns={columns} dataSource={products} />
                </Spin>
            </div>
        </div>
    );
}
