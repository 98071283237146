import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import './styles/style.scss';
import { setUser } from "../../../auth/services/auth-slice";
import { loadTokenState } from "../../../../utils/local-storage";

function AccountHeader() {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);

    useEffect(() => {
        dispatch(setUser(loadTokenState()?.user))
    }, []);



    return (
        <div className="animate__animated animate__fadeIn account-header">
            <div className="container">
                <div className="profile">
                    <div>
                        <img src="/img/profile.png" alt="logo" className="profile-img" />
                        <h6>{user?.lastname} {user?.firstname}</h6>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AccountHeader;
