import React, { useState } from "react";
import { Row, Col, Button, Form, Input, Spin, Select, notification } from "antd";
import { useSelector } from "react-redux";
import 'animate.css';
import { useCreateParentMutation } from "../services/parent-api";
const { Option } = Select;
const { TextArea } = Input;

export default function ParentCreatePage() {
    const [isLoading, setIsLoading] = useState(false);
    const [createParent] = useCreateParentMutation();
    const { user } = useSelector((state) => state.auth);
    const [form] = Form.useForm();


    const onFinish = (values) => {
        const data = { ...values, user: user.id }
        setIsLoading(true);
        createParent(data)
            .unwrap()
            .then(() => {
                setIsLoading(false);
                form.resetFields();
                notification.success({ message: 'Ajout effectué avec succès' })
            })
            .catch((error) => {
                setIsLoading(false);
                notification.error({ message: error.message })
            });
    };


    return (
        <div className="animate__animated animate__fadeIn account-profile">
            <div className="title">
                <h3>Ajouter un parent </h3>
            </div>

            <div className="content">
                <Spin tip="Chargement..." spinning={isLoading}>
                    <div className="form-wrapper">
                        <Form name="basic" layout="vertical" autoComplete="off" onFinish={onFinish} form={form}>
                            <Row gutter={24}>
                                <Col lg={12} xs={24}>
                                    <Form.Item label="Nom" name="libelle" rules={[{ required: true, message: "Champs requis!" }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col lg={12} xs={24}>
                                    <Form.Item label="sexe" name="sexe" rules={[{ required: true, message: "Champs requis!" }]}>
                                        <Select allowClear>
                                            <Option value="male">Male</Option>
                                            <Option value="female">Femelle</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col lg={12} xs={24}>
                                    <Form.Item label="Provenance" name="provenance" rules={[{ required: true, message: "Champs requis!" }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>


                                <Col lg={12} xs={24}>
                                    <Form.Item label="Poids" name="poids" rules={[{ required: true, message: "Champs requis!" }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col lg={12} xs={24}>
                                    <Form.Item label="Taille" name="taille" rules={[{ required: true, message: "Champs requis!" }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Form.Item label="Description" name="description">
                                <TextArea rows={4} className="form-control" />
                            </Form.Item>
                            <div style={{ margin: '20px 0px' }}>
                                {/* <input type="file" onChange={handleFileChange} /> */}
                            </div>
                            <Form.Item>
                                <Button type="primary" className="btn btn-primary" htmlType="submit">
                                    Enregistrer
                                </Button>
                            </Form.Item>
                        </Form>
                        <br />
                    </div>
                </Spin>
            </div>
        </div>
    );
}
