import React from "react";
import './style.scss';

export default function Banner({ title }) {
    return (
        <div className='banner'>
            <div className='container'>
                <h1><img src="img/trait-gauche.png" />{title}<img src="img/trait-droit.png" /></h1>
            </div>
        </div>
    );
}
