import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { Button, Form, Input, Row, Col, Modal } from "antd";
import { useNavigate } from "react-router-dom";
import 'animate.css';
import '../styles/style.scss';

export default function ResetPasswordPage() {
    const [isRegister, setIsRegister] = useState(false);
    // const [authlogin] = useAuthloginMutation();
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const onFinish = (values) => { };

    return (
        <div className="auth-page animate__animated animate__fadeIn">
            <div className='container'>
                <div className='section-auth'>
                    <h2>Mot de passe oublié ?</h2>
                    <p>Saisissez l'adresse e-mail que vous avez utilisée lors de votre inscription et nous vous enverrons des instructions pour réinitialiser votre mot de passe.</p>
                    <Form
                        name="basic"
                        initialValues={{ email: "", password: "" }}
                        onFinish={onFinish}
                        layout="vertical"
                        autoComplete="off"
                    >
                        <Form.Item
                            label="Numéro de téléphone"
                            name="number"
                            rules={[{ required: true, message: "Champs requis!" }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" className="btn btn-lg btn-primary" htmlType="submit" loading={isLoading}>
                                Envoyer
                            </Button>
                        </Form.Item>
                    </Form>

                    <p>
                        Vous n'êtes pas encore membre ?{" "}
                        <Link to={"/register"}>
                            Inscrivez-vous{" "}
                        </Link>
                    </p>
                </div>
            </div>
        </div>
    );
}
