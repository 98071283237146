import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_ROOT } from "../../../utils/global-vars";
import { loadTokenState } from "../../../utils/local-storage";

export const ladoumApi = createApi({
    reducerPath: "ladoumApi",
    baseQuery: fetchBaseQuery({
        baseUrl: API_ROOT,
        prepareHeaders: (headers) => {
            const token = loadTokenState()?.token;
            if (token) {
                headers.set("Authorization", `Bearer ${token}`);
            }
            return headers;
        },
    }),
    tagTypes: ["Ladoum"],
    endpoints: (builder) => ({
        createLadoum: builder.mutation({
            query: (payload) => ({
                url: `/ladoums`,
                method: "POST",
                body: payload,
            }),
            invalidatesTags: ["Ladoum"],
        }),

        updateLadoum: builder.mutation({
            query: (payload) => ({
                url: `/ladoums/${payload.id}`,
                method: "PUT",
                body: payload
            }),
            invalidatesTags: ["Ladoum"],
        }),

        deleteLadoum: builder.mutation({
            query: (id) => ({
                url: `/ladoums/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Ladoum"],
        }),

        fetchAllLadoums: builder.query({
            query: () => `/ladoums`,
            providesTags: ["Ladoum"],
        }),

        fetchLadoumById: builder.query({
            query: (id) => `/ladoums/${id}`,
            providesTags: ["Ladoum"],
        }),

        fetchLadoumByUseId: builder.query({
            query: (id) => `/ladoums/users/${id}`,
            providesTags: ["Ladoum"],
        }),
    }),
});

export const {
    useCreateLadoumMutation,
    useUpdateLadoumMutation,
    useDeleteLadoumMutation,
    useFetchAllLadoumsQuery,
    useFetchLadoumByIdQuery,
    useFetchLadoumByUseIdQuery
} = ladoumApi;
