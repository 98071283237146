import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Form, Input, Alert } from "antd";
import { useNavigate } from "react-router-dom";
import { useAuthRegisterMutation } from "../services/auth-api";
import 'animate.css';
import '../styles/style.scss';

export default function RegisterPage() {
    const [authRegister] = useAuthRegisterMutation();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState();
    const navigate = useNavigate();

    const onFinish = (values) => {
        setIsLoading(true);
        authRegister(values)
            .unwrap()
            .then(() => {
                navigate("/login");
            })
            .catch((error) => {
                setError(error.data.message);
                setIsLoading(false);
            });
    };

    return (
        <div className="auth-page animate__animated animate__fadeIn">
            <div className='container'>
                <div className='section-auth'>
                    <h2>Créer un compte</h2>
                    <p>Pour acheter ou vendre en toute sécurité, créez un compte ou connnectez-vous.</p>
                    <Form
                        name="basic"
                        initialValues={{ email: "", password: "" }}
                        onFinish={onFinish}
                        layout="vertical"
                        autoComplete="off"
                    >
                        <Form.Item
                            label="Nom"
                            name="lastname"
                            rules={[{ required: true, message: "Champs requis!" }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Prénom"
                            name="firstname"
                            rules={[{ required: true, message: "Champs requis!" }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Téléphone"
                            name="phone"
                            rules={[{ required: true, message: "Champs requis!" }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Mot de passe"
                            name="password"
                            rules={[{ required: true, message: "Champs requis!" }]}
                        >
                            <Input.Password />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" className="btn btn-lg btn-primary" htmlType="submit" loading={isLoading}>
                                Creer un compte
                            </Button>
                        </Form.Item>
                    </Form>
                    {error && <Alert message={error} type="error" />}
                    <p>
                        Vous avez déjà un compte ?{" "}
                        <Link to={"/login"}>Connexion</Link>
                    </p>
                    <p className="cgu-link">En créant un compte et en vous connectant, vous acceptez les{" "}
                        <Link to={"#"}> Conditions et Charte d'utilisation.</Link> </p>
                </div>
            </div>
        </div>
    );
}
