import React, { useEffect, useState } from "react";
import { Col, Row } from 'antd';
import 'animate.css';
import './styles/style.scss';
import { API_ROOT, calculateAge } from "../../utils/global-vars";
import { useLocation } from "react-router-dom";

export default function LadoumsDetailPage() {
    const { state } = useLocation();
    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${API_ROOT}/ladoums/${state.id}`);
                if (!response.ok) {
                    throw new Error("Failed to fetch data");
                }
                const result = await response.json();
                setData(result);
                setLoading(false);
            } catch (err) {
                setLoading(false);
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    return (
        <div className="landoum-detail-page animate__animated animate__fadeIn">
            <div className="section-1">
                <div className='container'>
                    {data &&
                        <Row>
                            <Col lg={14}>
                                <div className="blc-left">
                                    <div className="title">{state.libelle}</div>
                                    <div className="desc">{state.description}</div>
                                    <div className="subtitle">Poids: <span>{data.poids} kg</span></div>
                                    <div className="subtitle">Sexe: <span>{data.sexe}</span> </div>
                                    <div className="subtitle">Age: <span>{calculateAge(data.dob ?? "0")} </span> </div>
                                    <div className="subtitle">Père: <span>{data.pere?.libelle} </span> </div>
                                    <div className="subtitle">Provenance du Père: <span>{data.pere?.provenance}</span> </div>
                                    <div className="subtitle">Mère: <span>{data.mere?.libelle}</span> </div>
                                    <div className="subtitle">Provenance de la Mère: <span>{data.mere?.provenance}</span> </div>
                                    <div className="subtitle">Bergerie: <span>{data.bergerie?.libelle}</span> </div>
                                    <div className="subtitle">Proprietaire: <span>{data.user.firstname} {data.user.lastname}</span></div>
                                </div>
                            </Col>

                            <Col lg={10}>
                                <div className="blc-right">
                                    <div className="img" style={{ backgroundImage: `url(${API_ROOT}/uploads/${state.image})` }} />
                                </div>
                            </Col>
                        </Row>
                    }
                </div>
                <div className="section-2">
                    <div className="container">
                        <Row>
                            <Col lg={8}></Col>
                        </Row>
                    </div>
                </div>
            </div>
        </div>
    );
}
