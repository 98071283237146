import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_ROOT } from "../../../utils/global-vars";
import { loadTokenState } from "../../../utils/local-storage";

export const bergeriesApi = createApi({
    reducerPath: "bergeriesApi",
    baseQuery: fetchBaseQuery({
        baseUrl: API_ROOT,
        prepareHeaders: (headers) => {
            const token = loadTokenState()?.token;
            if (token) {
                headers.set("Authorization", `Bearer ${token}`);
            }
            return headers;
        },
    }),
    tagTypes: ["Bergeries"],
    endpoints: (builder) => ({
        createBergeries: builder.mutation({
            query: (payload) => ({
                url: `/bergeries`,
                method: "POST",
                body: payload,
            }),
            invalidatesTags: ["Bergeries"],
        }),

        updateBergeries: builder.mutation({
            query: (payload) => ({
                url: `/bergeries/${payload.id}`,
                method: "PUT",
                body: payload,
            }),
            invalidatesTags: ["Bergeries"],
        }),

        deleteBergeries: builder.mutation({
            query: (id) => ({
                url: `/bergeries/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Bergeries"],
        }),

        fetchAllBergeriess: builder.query({
            query: () => `/bergeries`,
            providesTags: ["Bergeries"],
        }),

        fetchBergeriesById: builder.query({
            query: (id) => `/bergeries/${id}`,
            providesTags: ["Bergeries"],
        }),

        fetchBergeriesByUseId: builder.query({
            query: (id) => `/bergeries/users/${id}`,
            providesTags: ["Bergeries"],
        }),
    }),
});

export const {
    useCreateBergeriesMutation,
    useUpdateBergeriesMutation,
    useDeleteBergeriesMutation,
    useFetchAllBergeriessQuery,
    useFetchBergeriesByIdQuery,
    useFetchBergeriesByUseIdQuery
} = bergeriesApi;
