import React, { useState } from "react";
import { Avatar, Space, Spin, Table, notification } from "antd";
import { useSelector } from "react-redux";
import { useDeleteLadoumMutation, useFetchLadoumByUseIdQuery } from "../services/ladoums-api";
import { API_ROOT, textToSlug } from "../../../utils/global-vars";
import 'animate.css';
import { NavLink } from "react-router-dom";

export default function LadoumsListPage() {
    const { user } = useSelector((state) => state.auth);
    const { data: products, isLoading } = useFetchLadoumByUseIdQuery(user.id);
    const [deleteLadoum] = useDeleteLadoumMutation();
    const [loading, setLoading] = useState(false);

    const columns = [
        {
            title: "Nom",
            key: "libelle",
            render: (record) => (
                <div className="btn-view">
                    <span>
                        <Space>
                            <Avatar src={`${API_ROOT}/uploads/${record?.image}`} alt="avatar" size={54} />
                            {record.libelle}
                        </Space>
                    </span>
                </div>
            ),
        },

        {
            title: "Sexe",
            key: "sexe",
            render: (record) => (
                <div className="btn-view">
                    <span>{record.sexe}</span>
                </div>
            ),
        },

        {
            title: "Poids",
            key: "poids",
            render: (record) => (
                <div className="btn-view">
                    <span>{record.poids}</span>
                </div>
            ),
        },

        {
            title: "Père",
            key: "pere",
            render: (record) => (
                <div className="btn-view">
                    <span>{record.pere?.libelle}</span>
                </div>
            ),
        },
        {
            title: "Provenance du père",
            key: "pere",
            render: (record) => (
                <div className="btn-view">
                    <span>{record.pere?.provenance}</span>
                </div>
            ),
        },

        {
            title: "Mère",
            key: "mere",
            render: (record) => (
                <div className="btn-view">
                    <span>{record.mere?.libelle}</span>
                </div>
            ),
        },

        {
            title: "Provenance de la mère",
            key: "mere",
            render: (record) => (
                <div className="btn-view">
                    <span>{record.mere?.provenance}</span>
                </div>
            ),
        },
        {
            title: "Bergeries",
            key: "bergerie",
            render: (record) => <span>{record?.bergerie?.libelle}</span>,
        },

        {
            title: "Action",
            render: (record) => (
                <div className="btn-view">
                    <Space>
                        <NavLink to={`/account/ladoums/edit/${textToSlug(record.libelle)}`} state={record}>
                            <span className="link-edit">Editer</span>
                        </NavLink>
                        <span className="link-delete" onClick={() => handleDelete(record.id)}>Supprimer</span>
                    </Space>
                </div>
            ),
        },
    ];

    const handleDelete = (id) => {
        setLoading(true);
        deleteLadoum(id)
            .unwrap()
            .then(() => {
                setLoading(false);
                notification.success({ message: 'Suppression effectuée avec succès' })
            })
            .catch((error) => {
                setLoading(false);
                notification.error({ message: error.message })
            });
    }

    return (
        <div className="animate__animated animate__fadeIn account-profile">
            <div className="title">
                <h3>Liste des ladoums </h3>
            </div>

            <div className="content">
                <Spin tip="Chargement..." spinning={isLoading || loading}>
                    <Table columns={columns} dataSource={products} />
                </Spin>
            </div>
        </div>
    );
}
